import React from 'react';
import Button from '../elements/Button'
import image from '../../images/family.png'
import { PhoneIcon, EnvelopeIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import ContactForm from '../forms/ContactForm';

const Contact = () => {
    return <section id="contact" className="my-12 container mx-auto">
        <div className="rounded-lg drop-shadow grid grid-cols-1 lg:grid-cols-5">
            <div className="col-span-2 rounded-t-lg lg:rounded-tr-none lg:rounded-l-2xl bg-cover bg-center" style={{ backgroundImage: `url(${image})` }}>
                <div className="rounded-t-lg lg:rounded-tr-none lg:rounded-l-2xl bg-slate-700/80 h-full">
                    {/* <div className="rounded-t-2xl lg:rounded-tr-none lg:rounded-l-2xl bg-[#25323f]/80 h-full"> */}
                    <div className="mx-auto text-center lg:text-left lg:px-12 inset-y-1/2 py-6 lg:pt-48">
                        <h2 className="text-white pb-2">Get In Touch</h2>
                        <p className="text-gray-200 pb-6"></p>
                        <p className="text-gray-200 pb-6">3300 S Broadway Ave <br /> Suite 102 <br />Tyler, TX 75701</p>
                        <div className="">
                            <PhoneIcon className="h-6 text-gray-400 inline-flex" />
                            <p className="text-gray-200 ml-2 inline-flex">(903) 747-3911</p>
                        </div>
                        <div className="">
                            <EnvelopeIcon className="h-6 text-gray-400 inline-flex" />
                            <p className="text-gray-200 ml-2 inline-flex">info@rosemanwealth.com</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" col-span-3 py-12 px-8 bg-gray-50 rounded-b-lg lg:rounded-bl-none lg:rounded-r-lg">

                <ContactForm />
            </div>
        </div>
    </section>;
};

export default Contact;
