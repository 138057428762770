import React from 'react'
import Layout from '../components/layout'
import Contact from '../components/sections/Contact'

const contact = () => {
    return (
        <Layout>
            <Contact />
        </Layout>
    )
}

export default contact