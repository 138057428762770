import React from 'react';
import { navigate } from 'gatsby';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Persist } from 'formik-persist'
import * as Yup from 'yup'
import { CheckCircleIcon } from '@heroicons/react/24/outline';

const Fieldset = ({ name, label, ...rest }) => (
    <React.Fragment>
        <label htmlFor={name}>{label}</label>
        <Field id={name} name={name} {...rest} />
    </React.Fragment>
);

const ContactForm = ({ values }) => {

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    return <div className='container mx-auto my-12'>
        <Formik
            initialValues={{
                name: "",
                email: "",
                phone: "",
                reference: "",
                checked: "",
            }}
            validationSchema={Yup.object({
                name: Yup.string().required("Required"),
                email: Yup.string().email("Invalid email address").required("Required"),
                phone: Yup.string().matches(phoneRegExp, "Invalid phone number").required("Required"),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setTimeout(() => {
                    // const myUrl = "http://localhost:1337/api/contact-forms";
                    const myUrl = "http://localhost:8055/items/contact";
                    fetch(myUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        // body: JSON.stringify({ data: values })
                        body: JSON.stringify(values)
                    }).then(function (response) {
                        console.log(response.status);
                        const success = true;
                        // success 
                        if (response.status >= 200 && response.status < 300) {
                            console.log(JSON.stringify({ data: values }, null, 2));
                            setSubmitting(false);
                            resetForm();
                            // navigate("/login/");
                            const success = true;
                        }
                        // fail 
                        else {
                            alert("Error code " + response.status);
                        }
                    });
                }, 400);
            }}

        >
            {({ values, isSubmitting, success }) => (
                <Form>

                    <div className="space-y-4">
                        {/* name  */}
                        <div className="">
                            <div className="flex flex-row justify-between">
                                <label htmlFor="name" className="">Your Name</label>
                                <ErrorMessage className="" name="name">{msg => <span className="form-error">{msg}</span>}</ErrorMessage>
                            </div>
                            <Field name="name" type="text" className="" />
                        </div>
                        <div className="flex justify-between space-x-4">

                            {/* email  */}
                            <div className="flex-grow">
                                <div className="flex flex-row justify-between">
                                    <label htmlFor="email" className="">Email Address</label>
                                    <ErrorMessage name="email">{msg => <span className="form-error">{msg}</span>}</ErrorMessage>
                                </div>
                                <Field name="email" type="email" className="" />
                            </div>
                            {/* phone  */}
                            <div className="flex-grow">
                                <div className="flex flex-row justify-between">
                                    <label htmlFor="phone" className="">Phone Number</label>
                                    <ErrorMessage name="phone">{msg => <span className="form-error">{msg}</span>}</ErrorMessage>
                                </div>
                                <Field name="phone" type="text" className="" />
                            </div>
                        </div>

                        {/* reference  */}
                        <div className="">
                            <div className="flex flex-row justify-between">
                                <label htmlFor="reference" className="">How did you hear about us?</label>
                                <ErrorMessage name="reference">{msg => <span className="form-error">{msg}</span>}</ErrorMessage>
                            </div>
                            <Fieldset name="reference" as="select">
                                <option value="">Select an option</option>
                                <option value="Friend/Family">Friend/Family</option>
                                <option value="Financial Professional">Financial Professional</option>
                                <option value="Web Search">Web Search</option>
                                <option value="Social Media">Social Media</option>
                                <option value="Other">Other</option>
                            </Fieldset>
                        </div>
                        {/* checkbox cards */}
                        <div role="group" aria-labelledby="checkbox-group" className="flex flex-col">
                            <label htmlFor="group" className="">Select from the following.</label>
                            <label className="flex-grow items-center">
                                <Field type="checkbox" name="checked" value="One" className="hidden peer" />
                                {/* <div className="w-full py-3 border text-center rounded bg-white peer-checked:bg-blue-50 border-gray-300 cursor-pointer peer-checked:border-blue-500 peer-checked:border-2">Schedule a consultation</div> */}
                                {/* <div className="flex space-x-2 items-center py-3"> */}
                                <div className="border rounded border-gray-600 h-4 w-4 peer-checked:border-blue-500"></div><div className="font-normal">Schedule a consultation</div>
                                {/* </div> */}
                            </label>
                            <label className="flex-grow">
                                <Field type="checkbox" name="checked" value="Two" className="hidden peer" />
                                <div className="w-full py-3 border text-center rounded bg-white peer-checked:bg-blue-50 border-gray-300 cursor-pointer peer-checked:border-blue-500 peer-checked:border-2">Sign up for newsletter</div>
                            </label>

                        </div>

                        <button type="submit" disabled={isSubmitting} className="button mt-2 grow-0 flex-none">Submit</button>
                    </div>
                    <p className={"text-red " + (success ? 'flex' : 'hidden')}>
                        Succeeded
                    </p>


                    <Persist name="contact-form" />


                </Form>
            )}
        </Formik>
    </div>;
};

export default ContactForm;
